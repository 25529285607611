<template>
  <el-dialog :visible.sync="show"
             :width="width"
             :close-on-click-modal="closeOnClickModal"
             :show-close="false"
  >
    <slot name="header">
      <div class="c-model-header flex col-center row-between" slot="title">
        <span class="fz-16">{{title}}</span>
        <div class="cursor"  @click="show = false">
          <i class="el-icon-close fz-24 color-gray"/>
        </div>
      </div>
    </slot>
    <slot></slot>
    <slot name="footer">
      <div class="footer flex row-end pt-30 pb-24">
        <el-button size="small" type="default" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" @click="confirm">{{ confirmText }}</el-button>
      </div>
    </slot>
  </el-dialog>
</template>
<script>
export default {
  name: "index",
  props: {
    value: false,
    title: '',
    width: {
      type: String,
      default: '30%'
    },
    confirmText: {
      type: String,
      default: '确定'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    asyncClose: {
      type: Boolean,
      default: false
    },
    closeOnClickModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(val) {
        if (!val) {
          if (this.asyncClose) {
            this.$emit("beforeClose", val)
            return
          }
          this.$emit("input", val)
          this.$emit("close", val)
        }
      }
    }
  },
  methods: {
    cancel() {
      this.show = false
      this.$emit('cancel')
    },
    confirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
