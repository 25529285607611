<template>
  <c-model v-model="show" width="412px" title="支付密码" @close="onClose" @confirm="submit">
    <el-input v-model="password" placeholder="请输入支付密码" type="password" size="small" ref="pwd" class="mt-34" @keyup.enter="submit"/>
  </c-model>
</template>
<script>
export default {
  name: "index",
  props: {
    value: false,
  },
  data() {
    return {
      password: ''
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    submit() {
      if (!this.password) {
        this.$message.error('请输入支付密码')
        return false
      }
      this.show = false
      this.$emit('confirm', this.password)
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.password = ''
        this.$nextTick(() => {
          this.$refs.pwd.focus();
        });
      }
    }
  }
}
</script>



<style scoped lang="scss">

</style>
