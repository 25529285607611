<template>
  <div class="normal fz-14 tc p20 color-gray" v-if="loading">加载中...</div>
  <div class="normal fz-14 tc p20 color-gray" v-else-if="finished">~没有更多了~</div>
</template>

<script>
    export default {
        props: {
            finished: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {

            };
        }
    }
</script>

<style lang="scss">

</style>
