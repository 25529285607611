<template>
  <c-model v-model="show" title="包时" width="903px" class="packet-time-modal" @close="show = false">
    <span slot="footer"></span>
    <div class="tab-list">
      <div class="tab-item" :class="{'active': active == 1}" @click="changeTab(1)">定额包时</div>
      <div class="tab-item" :class="{'active': active == 2}" @click="changeTab(2)">包时卡</div>
    </div>
    <packet-time ref="packetTime" :memberInfo="memberInfo" @select="(item) => {this.item = item}" v-show="active == 1"/>
    <packet-card ref="packetCard" :memberInfo="memberInfo" @select="(item) => {this.item = item}" v-show="active == 2"/>

    <div class="bottom flex row-between col-bottom">
      <div class="member-info">
        <div class="flex"><div>上网卡号：</div>{{ memberInfo.memberNumber || '--' }}</div>
        <div class="flex"><div>上网用户：</div>{{ memberInfo.memberName || '--' }}</div>
        <div class="flex"><div>账户余额：</div><span class="color-red">{{ memberInfo.balance || 0 }}</span>元
          (现金:{{ memberInfo.availMoney || 0 }}元 赠送金:{{ memberInfo.presentedMoney || 0 }}元)
        </div>
        <div class="flex">
          <div>消费模式：</div>
          <div>
            <span class="color-red" v-if="memberInfo.entriesId">
             {{ memberInfo.entriesName }}
              <div>({{ memberInfo.endTime }}结束)</div>
            </span>
            <template v-else>普通消费</template>
          </div>
        </div>
        <div class="flex" v-if="memberInfo.reserveQuotaName">
          <div class="">预定包时：</div>
          <div class="color-red flex-1">{{ memberInfo.reserveQuotaName }}（{{dateFormat(memberInfo.reserveStartTime, 'hh:mm')+'开始'}}）</div>
        </div>
      </div>
      <div>
        <el-button type="default" size="small" class="submit-btn ml-20" @click="show = false">取消</el-button>
        <el-button type="primary" size="small" class="submit-btn ml-20" :disabled="!item" :class="{disabled: !item}"
                   @click="submit">{{active == 1 ? '余额包时': '确认使用'}}
        </el-button>
      </div>
    </div>
  </c-model>
</template>
<script>
import {getMemberInfo} from "@/api/member";
import {decryptByDESModeCBC} from "@/common/utils/des";
import {addOnLineRateActivity, rateActivity, useRateQuotaCard} from "@/api/packageTime";
import {dateDiff, dateFormat} from "@/common/utils";
import packetTime from "./packetTime.vue";
import packetCard from "./packetCard.vue";

export default {
  name: "index",
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  components: {
    packetTime,
    packetCard
  },
  data() {
    return {
      item: null,
      memberInfo: {},
      active: 1
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {
    dateFormat,
    init() {
      this.item = null
      getMemberInfo().then(res => {
        if (res.code == 200) {
          this.memberInfo = JSON.parse(decryptByDESModeCBC(res.msg));
          this.memberInfo.startTime = this.memberInfo.startTime ? dateFormat(this.memberInfo.startTime, 'yyyy-MM-dd hh:mm') : ''
          this.memberInfo.endTime = this.memberInfo.endTime ? dateFormat(this.memberInfo.endTime, 'yyyy-MM-dd hh:mm') : ''
          this.changeTab(1)
        }
      }).catch(res => {

      });
    },
    changeTab(type) {
      this.active = type
      this.item = null
      this.$nextTick(() => {
        if (type == 1) {
          this.$refs.packetTime.getList()
        } else {
          this.$refs.packetCard.getList()
        }
      })
    },
    submit() {
      if (this.active == 1) {
        this.handlePacketTime();
      } else {
        this.handlePacketCard();
      }
    },
    // 定额包时
    handlePacketTime() {
      let time;
      if (this.item.startTime == '00:00' && this.item.endTime == '00:00') {
        time = this.formatTime(this.item.duration)
      } else {
        let year = dateFormat(new Date(), 'yyyy-MM-dd')
        this.item.endTime = this.item.endTime == '00:00' ? '24:00' : this.item.endTime
        time = dateDiff(new Date(), year + ' ' + this.item.endTime + ':00', false)
      }
      this.$confirm(`距离包时还剩${time}结束，确认继续包时吗？`, '电竞蜂提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
      }).then(() => {
        addOnLineRateActivity({memberId: this.memberInfo.memberId, activityId: this.item.id}).then(res => {
          if (res.code == 200) {
            this.$message.success('包时成功')
            this.show = false
          } else {
            this.$message.error(res.msg || '包时失败，请稍后重试')
          }
        })
      })
    },
    // 包时卡
    handlePacketCard() {
      let lastTime;
      if (this.item.type == 1) {
        lastTime = this.item.endTime
      } else {
        let year = new Date().getFullYear()
        let endTime = this.memberInfo.endTime ? new Date(this.memberInfo.endTime) : new Date()
        endTime = parseInt(endTime.getTime() / 1000)
        lastTime = dateFormat((endTime + parseInt(this.item.duration) * 60) * 1000)
      }
      this.$confirm(`包时卡使用后不可撤销，使用后至${lastTime}结束包时，确定使用吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        useRateQuotaCard({memberId: this.memberInfo.memberId, cardId: this.item.id}).then(res => {
          if (res.code == 200) {
            this.$message.success('使用成功')
            this.show = false
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    formatTime(time) {
      let hour = Math.floor(time / 60)
      let min = Math.floor(time % 60)
      return (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min)
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.init()
      }
    }
  }
}
</script>

<style lang="scss">
.packet-time-modal {
  .tab-list {
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 243px;
      right: 0;
      bottom: 0;
      height: 1px;
      background: $-border-color;
    }

    .tab-item {
      width: 120px;
      padding: 8px 0;
      border: 1px solid $-border-color;
      text-align: center;
      cursor: pointer;

      &:first-child {
        border-right: 0;
      }

      &.active {
        @include font_color();
        border-bottom: 0;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  .el-table {
    .active {
      @include background_color();
      color: #fff;
    }

    .disabled {
      color: #ccc;
    }
  }

  .bottom {
    line-height: 24px;
    font-size: 14px;
    margin-top: 16px;
    color: #000;
    padding-bottom: 20px;

    .submit-btn {
      border-radius: 4px;
      padding: 10px 20px;
    }
  }
}
</style>
