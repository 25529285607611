<template>
  <div class="scroll_parent_box" @mouseenter="mEnter" @mouseleave="mLeave">
    <div class="scroll_list" :style="{ transform: `translate(0px,-${scrollTop}px)` }">
      <div :ref="id">
        <slot></slot>
      </div>
      <div v-html="copyHtml"></div>
    </div>
  </div>
</template>

<script>
let timer = null;
export default {
  name: "InfiniteScroll",
  props: {
    id: {type: String, default: 'listScroll'},
    mHover: {type: Boolean, default: false},
  },
  data() {
    return {
      scrollTop: 0, //列表滚动高度
      speed: 25, //滚动的速度
      copyHtml: '', //复制多一份防止滚动到后面出现空白
    };
  },
  methods: {
    initScroll() {
      this.$nextTick(() => {
        this.scrollTop = 0
        this.copyHtml = this.$refs[this.id].innerHTML
        this.startScroll()
      })
    },
    // 鼠标移入停止滚动
    mEnter() {
      if (this['timer'+this.id]) {
        clearInterval(this['timer'+this.id]);
      }
    },
    // 鼠标移出继续滚动
    mLeave() {
      this.startScroll()
    },
    // 开始滚动
    startScroll() {
      if (this['timer'+this.id]) {
        clearInterval(this['timer'+this.id]);
      }
      this['timer'+this.id] = setInterval(this.scroll, this.speed);
    },
    // 滚动处理方法
    scroll() {
      this.$nextTick(() => {
        this.scrollTop++
        // 获取需要滚动的盒子的高度
        if (!this.$refs[this.id]) return
        let scrollItemBox = this.$refs[this.id].offsetHeight
        // 当判断滚动的高度大于等于盒子高度时，从头开始滚动
        if (this.scrollTop >= scrollItemBox) {
          this.scrollTop = 0
        }
      })
    }

  },
  destroyed() {
    if (this['timer'+this.id]) {
      clearInterval(this['timer'+this.id]);
    }
  }
};
</script>

<style scoped>
.scroll_parent_box {
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.scroll_list {
  transition: all 0ms ease-in 0s
}

.scroll_item {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
}
</style>
