<template>
  <viewer class="c-image" :style="style">
    <el-image :src="url || require('@/assets/images/cover.png')" :key="url" @click="show" :style="style"/>
  </viewer>
</template>
<script>
export default {
  name: "cImage",
  props: {
    url: '',
    width: {type: String, default: '100%'},
    height: {type: String, default: '100%'}
  },
  computed: {
    style() {
      return {
        width: this.width,
        height: this.height
      }
    },

  },
  methods: {
    show() {
      this.$viewerApi({
        images: [this.url],
        options: {
          toolbar: {
            zoomIn: false,
            zoomOut: false,
            oneToOne: false,
            reset: true,
            prev: true,
            play: false,
            next: false,
            rotateLeft: true,
            rotateRight: true,
            flipHorizontal: true,
            flipVertical: true,
            fullscreen: false,
            close: false
          }
        },
      })
    }
  }
}
</script>

<style scoped lang="scss">
.c-image {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
