<template>
  <c-model v-model="show" width="616px" title="选择优惠券" class="coupon-model" @confirm="submit">
    <div class="list">
      <div class="item flex" :class="{'active': currId == item.couponId}" v-for="(item, index) in list"
           @click="select(item, index)">
        <img src="@/assets/images/coupon-bg.png" alt="">
        <div class="left tc flex flex-center">
          <div>
            <div class="money"><span>{{ item.rate / 10 }}</span>折</div>
            <div class=""></div>
          </div>
        </div>
        <div class="right flex col-center">
          <div class="pr-10">
            <div class="title flex">
              <div class="label mr-10">{{sourceType[source]}}</div>
              <div class="line-clamp flex-1">{{ item.couponName }}</div>
            </div>
            <div class="time color-gray mt-15" v-if="item.startTime">{{ item.startTime }} ~ {{ item.endTime }}</div>
            <div class="mt-18" v-if="item.remark">{{ item.remark }}</div>
          </div>
          <div class="btn">
            <i class="iconfont icon-round fz-20" v-if="currId != item.couponId"/>
            <i class="iconfont icon-roundcheckfill fz-20" v-else/>
          </div>
        </div>
      </div>
    </div>
  </c-model>
</template>
<script>
export default {
  name: "index",
  props: {
    value: true,
    list: {type: Array, default: () => []},
    selectId: null,
    source: {type: Number, default: 1}, // 来源 1-饮品券 2-门店活动
  },
  data() {
    return {
      currId: 0,
      sourceType: {
        1: '饮品券',
        2: '餐食券'
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {
    select(item, index) {
      if (this.currId && item.couponId == this.currId) {
        this.currId = null
        return
      }
      this.currId = item.couponId
    },
    submit() {
      this.show = false
      if (!this.currId) {
        this.$emit("select", null)
        return
      }
      let item = this.list.find(item => item.couponId == this.currId)
      this.$emit("select", item)
    }
  },
  watch: {
    selectId() {
      this.currId = this.selectId
    }
  }
}
</script>


<style lang="scss">
@import "index";
</style>
