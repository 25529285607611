<template>
  <el-dialog class="ad-dialog" :visible.sync="show" :width="width" :show-close="false">
    <div class="ad-model" :style="{width,height}">
      <img src="@/assets/images/icon-close-w.png" class="icon-close no-drag" @click="show = false"/>
      <slot></slot>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "index",
  props: {
    value: {type: Boolean, default: false},
    width: {type: String, default: '620px'},
    height: {type: String, default: '650px'},
  },
  data() {
    return {}
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {},
  created() {
  },
  watch: {}
}
</script>

<style scoped lang="scss">
.ad-dialog {
  ::v-deep .el-dialog {
    padding: 0;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
  }
  .ad-model {
    position: relative;
    .icon-close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      z-index: 100;
    }
  }
}

</style>
