<template>
  <c-model v-model="show" width="590px" :title="title" :asyncClose="true" @beforeClose="beforeClose">
    <span slot="footer"></span>
    <div class="qrcode tc mt-40">
      <div class="mb-29">扫一扫支付，请在3分钟内完成支付</div>
      <img :src="qrcode" alt="" class="wh-182 mb-90"/>
    </div>
  </c-model>
</template>
<script>

import {payStatus} from "@/api/goods";
import {rechargePayStatus} from "@/api/wallet";
export default {
  name: "qrcodePay",
  props: {
    value: {type: Boolean, default: false},
    title: {type: String, default: '扫码支付'},
    orderNumber: {type: String, default: ''},
    orderType: {type: Number, default: 1}, // 订单类型 1-充值 2-商品
    qrcode: {type: String, default: ''}
  },
  data() {
    return {
      imgUrl: '',
      count: 0,
      max: 1000 * 180,
      inner: null
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {
    payStatus() {
      let params = {
        orderNumber: this.orderNumber
      }
      let api = this.orderType == 1 ? rechargePayStatus(params) : payStatus(params);
      api.then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg);
          this.fail();
          return false;
        }
        if (res.data.status == 2) {
          this.success()
        } else {
          if (this.count >= this.max) {
            this.fail();
            this.$message.error('支付超时');
            return false;
          }
          this.inner = setTimeout(() => {
            this.count += 1000;
            this.payStatus();
          }, 1000);
        }
      });
    },
    clearInter() {
      if (this.inner) {
        clearTimeout(this.inner);
      }
    },
    beforeClose() {
      this.$confirm('正在支付中，确定取消支付吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
        center: true
      }).then(() => {
        this.fail()
      }).catch(() => {

      });
    },
    onClose() {
      this.fail();
    },
    success() {
      this.show = false
      this.clearInter();
      this.$emit('success')
    },
    fail() {
      this.show = false;
      this.clearInter();
      this.$emit('fail')
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.payStatus();
      } else {
        this.clearInter();
      }
    }
  },
  destroy() {
    this.clearInter();
  },
}
</script>

<style scoped lang="scss">
.qrcode-pay-model {
  @include model('fixed');
  @include flex_center();

  .model-body {
    padding: 0 24px;
    .header {
      padding: 10px 0;
      border-bottom: 1px solid $-border-color;
    }
  }
}
</style>
