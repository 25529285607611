<template>
  <el-table :data="list" v-loading="loading" size="small" class="table mt-20" :cell-class-name="cellClass" @row-click="cellClick" height="360">
    <el-table-column prop="name" label="定额名称" show-overflow-tooltip/>
    <el-table-column prop="price" label="金额" align="center" width="80"/>
    <el-table-column prop="" label="可用时段" align="center">
      <template slot-scope="scope">{{scope.row.startTime}}-{{scope.row.endTime}}</template>
    </el-table-column>
    <el-table-column prop="duration" label="时长(分钟)" align="center"/>
    <el-table-column prop="areaName" label="可用区域" align="center" show-overflow-tooltip/>
    <el-table-column prop="" label="包时类型" align="center">
      <template slot-scope="scope">{{scope.row.type == 1 ? '标准定额': '包时长定额'}}</template>
    </el-table-column>
    <el-table-column prop="" label="会员权限" align="center">
      <template slot-scope="scope">{{scope.row.privilegeCardName || '全部'}}</template>
    </el-table-column>
  </el-table>
</template>
<script>
import {rateActivity} from "@/api/packageTime";
import {mapGetters} from "vuex";

export default {
  name: "packetTime",
  props: {
    memberInfo: {type: Object, default: () => {}}
  },
  data() {
    return {
      id: 0,
      list: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['memberId'])
  },
  methods: {
    getList() {
      this.id = 0
      this.loading = true
      rateActivity({memberId: this.memberId}).then(res => {
        this.list = res.data
        if (res.data.length > 0) {
        } else {
          this.$message.error("暂无可用包时套餐")
        }
      }).finally(() => {
        this.loading = false
      })
    },
    disable(row) {
      // 禁用或有定额或有预定不可选
      return row.status == 2 || this.memberInfo.entriesId || this.memberInfo.reserveQuotaId
    },
    cellClass({row, column}) {
      let obj = {}
      if (this.disable(row)) {
        return 'disabled'
      } else if (row.id == this.id && this.id != null) {
        obj = 'active cursor'
      } else {
        obj = 'normal cursor'
      }
      return obj
    },
    cellClick(row) {
      if (this.disable(row)) return
      if (row.id == this.id) {
        this.id = null
        this.$emit('select', null)
        return
      }
      this.id = row.id
      let item = this.list.find(item => item.id == this.id)
      this.$emit('select', item)
    },
  },
}
</script>


<style scoped lang="scss">

</style>
